
import './App.css';
import Hadder from './components/Hadder';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aboutus from './pages/Aboutus';
import Home from "./pages/Home"
import Contact from './pages/Content';
import Service from './pages/Service';
import Gallery from './pages/Gallery';
import Rooms from './pages/Rooms';
import Roomdetail from './pages/Roomdetail';
import Roomdetail2 from './pages/Roomdetail2';
import Roomdetail3 from './pages/Roomdetail3';
import Roomdetail4 from './pages/Roomdetail4';
import "./css/Buttions.css"
import Whatapp  from "./components/whatapp"
import banner from "../src/assets/vidhi/image-025.webp";
import bannerTwo from "../src/assets/vidhi/image-023.webp";
import homeBanner from "../src/assets/vidhi/image-016.webp";
import Error from './components/Error';
import Footer from './components/Footer';
function App() {
  return (
    <div className="App">
    <BrowserRouter> 
      <Whatapp></Whatapp>
      <Routes>

     <Route path='/' element={<Home banner={homeBanner} />}></Route>
     <Route path='/resort-in-murbad-with-water-park' element={<Aboutus banner ={banner}/>} ></Route>
     <Route path='/best-resort-in-murbad-for-family' element={<Service banner ={banner}/>}></Route>
     <Route path='/best-water-park-in-murbad' element={<Gallery banner ={banner}/>}></Route>
     <Route path='/water-park-with-rooms-in-murbad' element={<Rooms banner ={banner}/>}></Route>
     <Route path='/top-resort-near-murbad' element={<Contact banner ={banner}/>}></Route>
     <Route path='/best-resort-in-murbad-with-ac-rooms' element={<Roomdetail banner ={bannerTwo}/>}></Route>
     <Route path='/resort-with-dormitory-rooms-in-murbad' element={<Roomdetail2 banner ={bannerTwo}/>}></Route>
     {/* <Route path='/cottage-for-family-in-palghar' element={<Roomdetail3/>}></Route>
     <Route path='/cottage-for-couple-in-palghar' element={<Roomdetail4/>}></Route> */}
     <Route path='/*' element={<Error/>}></Route>
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
   
    </div>
  );
}

export default App;
