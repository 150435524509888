import React from "react";
import { BsCloudRainFill } from "react-icons/bs";

import "../css/Home.css";
import "../css/style.css";
import home from "../assets/h1.jpg";
import Hadder from "../components/Hadder";
import Footer from "../components/Footer";
import Testimonial from "../components/testimonal";
import h1 from "../assets/air-conditioner.png";
import h2 from "../assets/h2.png";
import h3 from "../assets/swimming-pool.png";
import h4 from "../assets/h4.png";
import h5 from "../assets/ludo.png";
import h6 from "../assets/wifi-router.png";
import h7 from "../assets/car-parking.png";
import h8 from "../assets/h8.png";
import beach from "../assets/beach.png";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Slider from "../components/slider";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import ban1 from "../assets/Banner/ban1.jpeg";
import acRoom from "../assets/vidhi-rooms/image-002.webp";
import dormitoryRoom from "../assets/vidhi-rooms/image-008.webp";
import room3 from "../assets/Home/room3.JPG";
import room4 from "../assets/Home/room4.JPG";
import ban4 from "../assets/Banner/ban4.JPG";
import ban8 from "../assets/Banner/ban8.JPG";
import { MetaTags } from "react-meta-tags";
import bann1 from "../assets/Home/bann1.jpeg";
import bann2 from "../assets/vidhi/image-005.jpg";
import aboutUs from "../assets/gallery/image-021.webp";
import sliders from "../assets/slider.png";
import dance from "../assets/dancing.png";
import dj from "../assets/dj-booth.png";
const Home = ({banner}) => {
  return (
    <div>
    <MetaTags>
        <title>Best Resort in Murbad | Vidhi Resort</title>
        <meta title=" Best Resort in Murbad | Vidhi Resort" />
        <meta
          name="description"
          content="Escape to tranquility at the Best Resort in Murbad. Immerse yourself in luxury and natural beauty, creating unforgettable moments. Book your retreat now!!"
        />
        <meta
          name="keywords"
          content="Best Resort in Murbad, Resort in Murbad with Water Park,Best Resort in Murbad for Family,Water Park with Rooms in Murbad,Best Resort in Murbad with AC Rooms,Resort with Dormitory Rooms in Murbad,Best Water Park in Murbad,Top Resort near Murbad"
        />
        <link
          rel="canonical"
          href="https://vidhiresort.in/"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content=" Best Resort in Murbad | Vidhi Resort"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vidhiresort.in/" />
        <meta
          property="og:description"
          content="Escape to tranquility at the Best Resort in Murbad. Immerse yourself in luxury and natural beauty, creating unforgettable moments. Book your retreat now!!"
        />
        <meta
          property="og:image"
          content="https://vidhiresort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>
      <Hadder></Hadder>
      <Slider banner={banner} ></Slider>
      <section className="container sethome">
        <div className="sethome">
          <div className="row">
            <Fade left>
              <h2 className="homeabout disp2">About us</h2>
              <div className="col-md-6">
                <img className="abut-Us" loading="lazy" src={aboutUs} alt="Best Resort " />
              </div>
            </Fade>
            <Fade right>
              <div className="col-md-6">
                <h2 className="homeabout disp1">About us</h2>
                <p className="peratext">
                Welcome to our best resort in Murbad, where nature's beauty unfolds in every direction. Nestled between majestic mountains and alongside a serene river, our resort offers a harmonious blend of tranquility and adventure. Immerse yourself in the breathtaking views of both the mountains and the river, creating an idyllic backdrop for your escape.<br/>
                But the experience doesn't stop there – indulge in the thrill of our water park, where laughter and joy intertwine with the refreshing waters. Whether you seek relaxation or excitement, our best resort in Murbad provides the perfect sanctuary for a memorable retreat. Join us in our best resort in Murbad for an unparalleled blend of nature's wonders and exhilarating aquatic fun. 
                </p>
                <div className="butoonleft">
                  <a href="#" className="custom-btn">
                    <span>
                      <Link to="/resort-in-murbad-with-water-park">Read More</Link>
                    </span>
                  </a>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="room room--popular padding-top padding-bottom">
        <div className="container">
          <div
            className="section-header aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="section-header__content">
              <h2>Vidhi Resort Best AC &amp; Dormitory Rooms.</h2>
             
            </div>
            
          </div>
          <div className="section-paragraph mb-5">
            <p className="">
              Welcome to our best resort in Murbad, Our AC dormitory rooms offer a perfect blend of affordability and convenience, ensuring a relaxing stay for budget-conscious travelers.
              </p>
              <p className="d-none">
              Nestled in a serene location, our best resort in Murbad provides a tranquil escape from the hustle and bustle, with modern amenities and a welcoming atmosphere. Experience the joy of communal living without compromising on comfort. Your adventure begins here!
              </p>
            </div>
          <div
            className="room__wrapper aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="row g-4">
              <Fade right>
                <div className="col-lg-6 col-md-6 ">
                  <div className="room__item room__item--style2">
                    <div className="room__item-inner">
                      <div className="room__item-thumb">
                        <img
                          src={acRoom}
                          loading="lazy"
                          alt=" Resort in Murbad "
                        />
                      </div>
                      <div className="room__item-content">
                        <div className="room__item-header">
                          <div className="room__item-name">
                            <h3>AC Rooms</h3>

                            <p style={{fontFamily:"Cardo,serif",margin:"2px 0px",}}> Chilled Comfort Oasis</p>
                            <a href="#" className="custom-btn">
                              <span>
                                <Link to="/best-resort-in-murbad-with-ac-rooms">
                                  Enquiry Now
                                </Link>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade left>
                <div className="col-lg-6 col-md-6">
                  <div className="room__item room__item--style2">
                    <div className="room__item-inner">
                      <div className="room__item-thumb">
                        <img
                          src={dormitoryRoom}
                          loading="lazy"
                          alt="Best Resort "
                        />
                      </div>
                      <div className="room__item-content">
                        <div className="room__item-header">
                          <div className="room__item-name">
                            <h3>
                              <a href="#">
                              Dormitory Rooms{" "}
                              </a>
                            </h3>
                            <p style={{fontFamily:"Cardo,serif", margin:"2px 0px"}}> Shared Space Haven</p>
                            <a href="#" className="custom-btn">
                              <span>
                                <Link to="/resort-with-dormitory-rooms-in-murbad">
                                  Enquiry Now
                                </Link>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
               <Link to="/water-park-with-rooms-in-murbad">
                <a href="#" className="custom-btn bg-black">
                  <span>
                   View More
                  </span>
                </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feature padding-top amenities-sec padding-bottom ame bg--section-color">
        <div className="container">
          <div
            className="section-header aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="section-header__content">
              <h2>Vidhi Resort: A Prime Selection for Your Vacation Retreat.</h2>

            </div>
           
          </div>
          <div className="section-paragraph mb-5">
          <p className="">
               Immerse yourself in a world where luxury, tranquility, and exceptional service converge.
               Choose our best resort in Murbad as your prime selection for an unparalleled retreat.

              </p>
              <p className="d-none">
              Our meticulously designed accommodations, coupled with stunning surroundings, create an ideal haven for relaxation. Whether you seek adventure or serene escapes, we cater to your every desire. Elevate your vacation experience with us, where every moment is curated to exceed your expectations. Your dream getaway begins here – make us your first and finest choice!

              </p>
          </div>
          <div className="feature__wrapper">
            <div className="row g-4">
            
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img src={h1} loading="lazy" alt="Best Resort " className="imagesize" />
                      </div>
                      <div className="feature__item-content">
                        <h5>AC</h5>
                        <p>Ac room</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={200}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                      {/* <BsCloudRainFill /> */}

                        <img src={dance} loading="lazy" alt=" Resort in Murbad" className="imagesize" />
                      </div>
                      <div className="feature__item-content">
                        <h5> Rain Dance</h5>
                        <p>Rain Dance</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={300}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img
                          src={h3}
                          loading="lazy"
                          alt="Best Resort "
                          className="imagesize"
                        />
                      </div>
                      <div className="feature__item-content">
                        <h5>Swimming Pool</h5>
                        <p> Swimming Pool</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={400}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img src={sliders} loading="lazy" alt=" Resort in Murbad" className="imagesize" />
                      </div>
                      <div className="feature__item-content">
                        <h5>3 Big Slides</h5>
                        <p>3 Big Slides</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
            </div>
            <div className="row g-4 padding-20">
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img src={h5} loading="lazy" alt="Resort in Murbad" className="imagesize" />
                      </div>
                      <div className="feature__item-content">
                        <h5>Games</h5>
                        <p>Games</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={200}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img
                          src={h6}
                          loading="lazy"
                          alt="Best Resort in Murbad"
                          className="imagesize"
                        />
                      </div>
                      <div className="feature__item-content">
                        <h5> Free Wifi / Cctv</h5>
                        <p> free Wifi/Cctv</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>

              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={300}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img
                          src={h7}
                          loading="lazy"
                          alt=" Resort in Murbad"
                          className="imagesize"
                        />
                      </div>
                      <div className="feature__item-content">
                        <h5> Open Parking</h5>
                        <p> Open Parking</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>

              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={400}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img
                          src={dj}
                          loading="lazy"
                          alt=" Resort in Murbad"
                          className="imagesize"
                        />
                      </div>
                      <div className="feature__item-content">
                        <h5>DJ</h5>
                        <p>DJ</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial padding-top padding-bottom">
        <div className="container">
          <div
            className="section-header aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="section-header__content">
              <h2>What Our Customers Have to Say About Vidhi Resort</h2>
             
            </div>
          </div>
          <div className="section-paragraph mb-5">
          <p className="">
              Discover what our valued customers are saying about their unforgettable experiences at our best resort in Murbad.
              </p>
              <p className="d-none">
              From the breathtaking views to the impeccable service, our guests consistently rave about the warmth and hospitality they encounter. Read testimonials that highlight our commitment to creating lasting memories, where each stay is more than just accommodation—it's a journey of comfort and joy. Join the chorus of satisfied guests who have made our resort their preferred destination. Your satisfaction is our greatest reward!
              </p>
          </div>
          <div
            className="testimonial__slider swiper swiper-initialized swiper-horizontal swiper-backface-hidden aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div
              className="swiper-wrapper"
              id="swiper-wrapper-10f34fe96bd622710"
              aria-live="off"
              style={{
                transitionDuration: "0ms",
              }}
            >
              <Testimonial></Testimonial>
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div>
      </section>
      {/* <h1 style={{ display: "none" }}> Best Resort in Murbad </h1> */}
    </div>
  );
};

export default Home;
