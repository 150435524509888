import React, { useState, useCallback } from "react";
import Hadder from '../components/Hadder'
import Footer from '../components/Footer'

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../components/photos";
import ban5 from "../assets/vidhi/image-025.jpg"
import { MetaTags } from 'react-meta-tags';
const Gallery1 = ({banner}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
        <Hadder></Hadder>
        <MetaTags>
        <title>Best Water Park in Murbad | Vidhi Resort  </title>
        <meta title=" Best Water Park in Murbad | Vidhi Resort  " />
        <meta
          name="description"
          content="Dive into excitement at the Best Water Park in Murbad. Splash, slide, and create unforgettable memories in a world of aquatic adventures."
        />
        <meta
          name="keywords"
          content="Best Resort in Murbad, Resort in Murbad with Water Park,Best Resort in Murbad for Family,Water Park with Rooms in Murbad,Best Resort in Murbad with AC Rooms,Resort with Dormitory Rooms in Murbad,Best Water Park in Murbad,Top Resort near Murbad"
        />
        <link
          rel="canonical"
          href="https://vidhiresort.in/best-water-park-in-murbad"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content=" Best Water Park in Murbad | Vidhi Resort  "
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vidhiresort.in/best-water-park-in-murbad" />
        <meta
          property="og:description"
          content="Dive into excitement at the Best Water Park in Murbad. Splash, slide, and create unforgettable memories in a world of aquatic adventures."
        />
        <meta
          property="og:image"
          content="https://vidhiresort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>
      <p className="d-none">
      Welcome to our best water park in Murbad, our Gallery page, an immersive visual journey into the enchanting world of our resort. Here, we invite you to explore the captivating moments and exquisite details that define the essence of our haven.

      Indulge in a visual feast as you peruse through snapshots of our luxurious accommodations. The best water park in Murbad, Each image captures the elegance and comfort of our rooms, showcasing the meticulous attention to detail that awaits every guest. From tastefully designed interiors to breathtaking views, our gallery is a testament to the opulence that defines our resort.

      Immerse yourself in the lush beauty of our surroundings through captivating photographs of our landscaped gardens, sparkling pools, and scenic vistas. Nature and architecture harmonize to create a serene ambiance that sets the stage for a truly unforgettable experience.

      In the best water park in Murbad, Discover the culinary delights that await you with glimpses of our delectable dishes and exquisite dining settings. Our gallery provides a taste of the gastronomic journey that awaits, where every meal is a celebration of flavors and artistry.

      Capture the essence of celebration and joy through images of events hosted at our best water park in Murbad. From dream weddings to corporate gatherings, each photo reflects the seamless execution and attention to detail that make our venue the perfect choice for special occasions.

      At our best water park in Murbad we believe in creating memories that last a lifetime. The Gallery page is a visual ode to the experiences and moments that define our commitment to excellence. We invite you to explore, dream, and envision your own story within the frames of our gallery – a glimpse into the extraordinary world that awaits at our resort.

      </p>
        <section className="page-header bg--cover second-banner" style={{backgroundImage:`url(${ banner})`}}>
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{color:"white"}}>Best Water Park in Murbad</h1>
           
              
          
          </div>
        </div>
      </section>
      <section className=" mb-5">
      <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
    </section>
      
      {/* <Footer></Footer> */}
    </div>
  )
}

export default Gallery1
