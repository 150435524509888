import React from 'react'
import "../css/Aboutus.css"
import "../css/style.css"
import Testimonial from "../components/testimonal";
import Footer from '../components/Footer'
import Hadder from '../components/Hadder'
import a1 from "../assets/a1.png"
import a2 from "../assets/a2.png"
import a3 from "../assets/a3.png"
import NumberCounter from 'number-counter';
import Aboutusi1 from "../assets/abouti1.png"
import Aboutusi2 from "../assets/abouti2.png"
import Accordion from 'react-bootstrap/Accordion';
import p1 from "../assets/P1.png"
import S1 from "../assets/S1.png"
import WIFI from "../assets/wifi.png"
import R1 from "../assets/R1.png"
import ban1 from "../assets/Banner/ban1.jpeg"
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import room4 from "../assets/Home/rom4.jpeg"
import ban4 from "../assets/Banner/ban4.JPG"
import ban2 from "../assets/Banner/ban2.jpeg"
import ban5 from "../assets/Banner/ban5.JPG"
import ban6 from "../assets/Banner/ban6.JPG"
import ban7 from "../assets/Banner/ban7.JPG"
import ban8 from "../assets/Banner/ban8.JPG"
import ban9 from "../assets/Banner/ban9.JPG"
import Aboutussecond from "../assets/Rooms/image-004.jpg"
import g1 from "../assets/Home/Room1/G1.JPG"
import G2 from "../assets/Home/Room1/G2.JPG"
import G3 from "../assets/Home/Room1/G3.JPG"
import G4 from "../assets/Home/Room1/G4.JPG"
import G5 from "../assets/Home/Room1/G5.JPG"
import { MetaTags } from 'react-meta-tags';
import bann3 from "../assets/Home/bann3.jpeg"
import aboutUsBanner from "../assets/vidhi/image-025.jpg";
import aboutUss from "../assets/vidhi/image-010.jpg";
import dj from "../assets/dj.png";
import slides from "../assets/slide.png";
import dance from "../assets/dance.png";
import games from "../assets/games.jpg";
import gal1 from "../assets/gallery/image-024.webp";
import gal2 from "../assets/gallery/image-025.webp";
import gal3 from "../assets/gallery/image-026.webp";

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination , Autoplay,Navigation } from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { photos } from "../components/photos";

const Aboutus = ({banner}) => {
  return (
    <div>
        <Hadder></Hadder>
        <MetaTags>
        <title> Resort in Murbad with Water Park | Vidhi Resort </title>
        <meta title="  Resort in Murbad with Water Park | Vidhi Resort " />
        <meta
          name="description"
          content="Experience the ultimate blend of relaxation and excitement at our Resort in Murbad with Water Park. Dive into luxury & family fun – your perfect getaway awaits."
        />
        <meta
          name="keywords"
          content="Best Resort in Murbad, Resort in Murbad with Water Park,Best Resort in Murbad for Family,Water Park with Rooms in Murbad,Best Resort in Murbad with AC Rooms,Resort with Dormitory Rooms in Murbad,Best Water Park in Murbad,Top Resort near Murbad"
        />
        <link
          rel="canonical"
          href="https://vidhiresort.in/best-resort-in-murbad-for-family/"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="  Resort in Murbad with Water Park | Vidhi Resort "
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vidhiresort.in/best-resort-in-murbad-for-family/" />
        <meta
          property="og:description"
          content="Experience the ultimate blend of relaxation and excitement at our Resort in Murbad with Water Park. Dive into luxury & family fun – your perfect getaway awaits."
        />
        <meta
          property="og:image"
          content="https://vidhiresort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>
   <section className="page-header bg--cover second-banner" style={{backgroundImage:`url(${ banner})`}}>
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{color:"white"}}> Resort in Murbad with Water Park</h1>
          </div>
        </div>
      </section>
      {/* ---------------About section------------------- */}
      <section className="featured-section-four about-page">
        <span className="dotted-pattern dotted-pattern-11" />
        <div className="circles-two">
          <div className="c-1" />
          <div className="c-2" />
        </div>
        <div className="container">
          <div className="row clearfix">
            {/*Text Column*/}
            <div className="text-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="sec-title">
                  <h2>
                    Relax in <br />
                    Our Luxury Resort
                  </h2>
                </div>
                <div className="text peratext">
                Welcome to our Vidhi resort, a resort in Murbad with water park where relaxation takes on a new level of refinement. Nestled in an oasis of tranquility, our resort invites you to unwind in the lap of opulence. Each facet of our establishment is meticulously designed to create an ambiance that harmonizes with the art of relaxation.
                </div>
                <ul className="features">
                  <li className='d-none peratext'>
                  From the moment you step into our elegantly appointed surroundings, you are enveloped in a world where every detail reflects our commitment to providing a sanctuary for rejuvenation. Our professionally trained staff is dedicated to ensuring that your every need is not just met but exceeded. resort in Murbad with water park With personalized service and attention to detail, we redefine the meaning of hospitality.
                  </li>

                  <li className="peratext">
                  Indulge in our luxuriously appointed accommodations, each thoughtfully curated to provide the utmost in comfort. From plush furnishings to cutting-edge amenities, we have spared no expense in creating an environment where you can escape the demands of daily life and immerse yourself in the lap of luxury.
                  </li>
                  <li className="peratext">
                  Choose our luxury resort in Murbad with water park for an experience that goes beyond the ordinary, where relaxation is an art form, and your well-being is our highest priority. Your journey to ultimate relaxation begins here, with us, where luxury meets tranquility in perfect harmony.
                  </li>
                  
                </ul>
              </div>
            </div>
            {/*Image Column*/}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <span className="dotted-pattern dotted-pattern-10" />
                <div className="image-box clearfix">
                  <figure
                    className="image wow fadeInUp"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <img src={gal1} loading='lazy' alt="Resort in Murbad "  />
                  </figure>
                  <figure
                    className="image wow fadeInRight"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <img src={gal2} loading="lazy" alt="Murbad with Water Park"  />
                  </figure>
                  <figure
                    className="image wow fadeInLeft"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                  >
                    <img src={gal3} loading="lazy" style={{maxHeight:"211px"}} alt=" Murbad with Water Park"  />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

<section className="gallery padding-bottom">
  <div className="container">
    <div className="section-header section-header--withnav">
      <div className="section-header__content">
        <h2 style={{textAlign:"center"}}>Vidhi Resort Beauty</h2>
       
      </div>
    </div>
    <div className='section-paragraph mb-5'>
    <p className="" >
        Nestled in nature's embrace, our haven exudes timeless elegance. 
        resort in Murbad with water park, Breathtaking landscapes surround meticulously designed architecture, creating a symphony of aesthetics.

        </p>
        <p className='d-none'>
        From sun-kissed pools to lush gardens, each element converges to form a masterpiece of serenity. Immerse yourself in the allure of our thoughtfully crafted spaces, where every corner reveals a glimpse of paradise. Resort in Murbad with water park Your retreat into beauty begins here, where the artistry of our resort seamlessly blends with the natural splendor that defines us. Welcome to a haven where beauty is not just seen but felt.
        </p>
    </div>
    <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        breakpoints={{
             0: {
                 slidesPerView: 1,
                 spaceBetween: 30,
             },
             768: {
                 slidesPerView: 2,
                 spaceBetween: 30,
             },
             1000: {
                 slidesPerView: 3,
                 spaceBetween: 30,
             },
         }}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination ,Navigation]}
        className="mySwiper"
      >
      {photos.map((v,i)=>{
        return(<>
          <SwiperSlide> <img src={v.src} style={{height: "470px",marginBottom:"50px"}} loading='lazy' alt=' Murbad with Water Park' /> </SwiperSlide>
        </>)
      })}
      </Swiper>
  </div>
</section>
{/* new section */}
<section className="facts-section-two alternate">
        <div className="circles">
          <div className="c-1" />
          <div className="c-2" />
        </div>
        <div className="container">
          <div className="sec-title mb-4 text-center" style={{textAlign:"center"}}>
            <h2 className='text-center'>
              Always Ready <br />
              for your service
            </h2>
            <div className="lower-text">
            Indulge in the farm-fresh delights of our culinary offerings. Your stay with us is not just a vacation. you will find luxury like home here.

            </div>
          </div>
          {/* <br/> */}
          {/*Facts Column*/}
          <div className="facts-box">
            <div className="row clearfix">
              <div className="fact-block col-lg-4 col-md-6 col-sm-12">
                <div className="fact-inner">
                  <div
                    className="fact-count wow zoomInStable"
                    data-wow-delay="0ms"
                    data-wow-duration="2000ms"
                  >
                    <div className="count-box counted">
                      <span
                        className="count-text"
                        data-stop={25}
                        data-speed={2000}
                      >
                        25
                      </span>
                      +
                    </div>
                  </div>
                  <h4>Working Staff</h4>
                  <div className="text">With a shared passion for hospitality, we thrive on teamwork, professionalism, and a genuine desire to exceed expectations.
</div>
                </div>
              </div>
              <div className="fact-block col-lg-4 col-md-6 col-sm-12">
                <div className="fact-inner">
                  <div
                    className="fact-count wow zoomInStable"
                    data-wow-delay="300ms"
                    data-wow-duration="2000ms"
                  >
                    <div className="count-box counted">
                      <span
                        className="count-text"
                        data-stop={712}
                        data-speed={5000}
                      >
                        20
                      </span>
                    </div>
                  </div>
                  <h4>Amenities</h4>
                  <div className="text">We offer a unique getaway, marrying the serenity of the nature with the richness of agricultural landscapes.
</div>
                </div>
              </div>
              <div className="fact-block col-lg-4 col-md-6 col-sm-12">
                <div className="fact-inner">
                  <div
                    className="fact-count wow zoomInStable"
                    data-wow-delay="600ms"
                    data-wow-duration="2000ms"
                  >
                    <div className="count-box counted">
                      <span
                        className="count-text"
                        data-stop={310}
                        data-speed={4000}
                      >
                        24/7
                      </span>
                    </div>
                  </div>
                  <h4>Service</h4>
                  <div className="text">Where dedicated individuals come together to create exceptional experiences for our guests.

</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
{/* new section */}
<section className="testimonial padding-top padding-bottom">
        <div className="container">
          <div
            className="section-header aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="section-header__content">
              <h2>What Our Customers Have to Say About Vidhi Resort</h2>
              {/* <p>
              In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual
              </p> */}
            </div>
          </div>
          <div
            className="testimonial__slider swiper swiper-initialized swiper-horizontal swiper-backface-hidden aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div
              className="swiper-wrapper"
              id="swiper-wrapper-10f34fe96bd622710"
              aria-live="off"
              style={{
                transitionDuration: "0ms",
              }}
            >
              <Testimonial></Testimonial>
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div>
      </section>



    </div>
  )
}

export default Aboutus
