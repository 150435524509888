import React, { useState } from 'react';
import { Nav, Navbar, Container } from "react-bootstrap";
import hadder from "../css/hadder.css"
// import Logo from "../assets/logo.png"
import { Link } from "react-router-dom";
import Logo from "../assets/vidhi/logo-removebg-preview.png";

const Hadder = () => {
  return (
    <>
    <div className="nav-bar-component">
        <section className="NavSection">
            {/* <div className="container"> */}
            <Navbar collapseOnSelect expand="lg" variant="dark" >
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    {/* <Link to='/'> */}
                    <Navbar.Brand href="/">
                        <div className='NavLogo'>
                             <img src={Logo}  alt="Resort in Murbad" /> 
                        </div>
                    </Navbar.Brand>
                    {/* </Link> */}
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
                        <Nav>
                            <Nav.Link href="#"> <Link to="/">Home</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/resort-in-murbad-with-water-park">About us </Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/best-resort-in-murbad-for-family">Events</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/water-park-with-rooms-in-murbad">Rooms</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/best-water-park-in-murbad">Gallery</Link></Nav.Link>
                            <Nav.Link><Link to="/top-resort-near-murbad">Contact Us</Link></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Nav className="Social-media-icons">
                    </Nav>
                </Container>
            </Navbar>
            {/* </div> */}
        </section>

    </div>
</>
  )
}

export default Hadder
