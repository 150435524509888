import React from 'react'
import Hadder from '../components/Hadder'
import "../css/Service.css"
import Bounce from 'react-reveal/Bounce';
import birthday from "../assets/birthday.jpg";
import meeting from "../assets/meeting.jpg";
import marriage from "../assets/marriage.jpg";
import kittyParty from "../assets/kity-party.png";
import { MetaTags } from 'react-meta-tags';
const Service = ({banner}) => {
  return (
    <div>
    <MetaTags>
        <title> Best Resort in Murbad for Family | Vidhi Resort  </title>
        <meta title="  Best Resort in Murbad for Family | Vidhi Resort  " />
        <meta
          name="description"
          content="Create lasting memories with your loved ones at the Best Resort in Murbad for Family happiness. From exciting activities to relaxing amenities."
        />
        <meta
          name="keywords"
          content="Best Resort in Murbad, Resort in Murbad with Water Park,Best Resort in Murbad for Family,Water Park with Rooms in Murbad,Best Resort in Murbad with AC Rooms,Resort with Dormitory Rooms in Murbad,Best Water Park in Murbad,Top Resort near Murbad"
        />
        <link
          rel="canonical"
          href="https://vidhiresort.in/best-resort-in-murbad-for-family/"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="  Best Resort in Murbad for Family | Vidhi Resort  "
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vidhiresort.in/best-resort-in-murbad-for-family/" />
        <meta
          property="og:description"
          content="Create lasting memories with your loved ones at the Best Resort in Murbad for Family happiness. From exciting activities to relaxing amenities."
        />
        <meta
          property="og:image"
          content="https://vidhiresort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>
        <Hadder></Hadder>
        <section className="page-header bg--cover second-banner" style={{backgroundImage:`url(${ banner})`}}>
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{color:"white"}}> Best Resort in Murbad for Family</h1>
          </div>
        </div>
      </section>
      <section className="service padding-top padding-bottom">
  <div className="container">
    <div className="section-header">
      <div className="section-header__content">
        <h2>Top Event Hosting Destination</h2>
        
      </div>
    </div>
    <div className='section-paragraph mb-5'>
    <p className="">
        Welcome to our best resort in Murbad for family, heralded as the pinnacle of hosting destinations, where we redefine hospitality with a commitment to excellence. Nestled in an enchanting landscape, our best resort in Murbad for family stands as a testament to luxury, sophistication, and personalized service.

        </p>
        <p className='d-none'>
        At the heart of our hosting philosophy is an unwavering dedication to providing an extraordinary experience. From the moment you arrive, our goal is to exceed your expectations. Our meticulously designed accommodations blend modern elegance with natural beauty, ensuring a serene and indulgent retreat.

        The best resort in Murbad for family ,What sets us apart as the top hosting destination is not just our luxurious surroundings but the seamless integration of top-tier amenities. Each room is a haven of comfort, adorned with opulent furnishings and equipped with cutting-edge facilities. Immerse yourself in our world-class dining options, where culinary artistry meets diverse palates, creating an unforgettable gastronomic journey.

        Our hosting prowess extends beyond leisure to cater to diverse occasions. Whether you're planning a corporate event, a dream wedding, or a family reunion, our resort provides an exquisite canvas for your vision. In our best resort in Murbad for family our professional event planning team is dedicated to orchestrating seamless and memorable gatherings, ensuring that every detail is meticulously attended to.

        As a top hosting destination, we understand that true luxury lies in the details. Our staff, trained in the art of hospitality, ensures that every guest receives personalized attention and service. Whether it's arranging bespoke experiences, organizing exclusive events, or anticipating your needs before you do, we pride ourselves on anticipating and exceeding your expectations.

        Choose our best resort in Murbad for family as your hosting destination, where unparalleled luxury meets exceptional service. Welcome to a world where dreams unfold, and every moment is curated to create cherished memories. Your journey into hosting perfection begins with us, where hospitality is not just a service but a refined art.

        </p>
    </div>
    <>
  <div className="dd_heading">
    
  </div>
  <main className="page-content">
  <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Birthday</h2>
        <p className="data-content">
        Joyful Birthday Celebration Experience.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Corporate Events</h2>
        <p className="data-content">
        Professional Networking, Corporate Gatherings.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading"> Marriage</h2>
        <p className="data-content">
        Union of Love and Togetherness.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Kitty Party</h2>
        <p className="data-content">
        Lively Social Gathering Experience.{" "}
        </p>
      </div>
    </div>
    </Bounce>
  </main>
  <main className="page-content">
    
  </main>
</>

 
  </div>
</section>
{/* <Footer></Footer> */}

    </div>
  )
}

export default Service
