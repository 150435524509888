import React from 'react'
import Hadder from '../components/Hadder'
import Footer from '../components/Footer'
import room1 from "../assets/Home/room1.JPG"
import room2 from "../assets/Home/room2.jpeg"
import room3 from "../assets/Home/room3.JPG"
import room4 from "../assets/Home/room4.JPG"
import acRoom from "../assets/vidhi-rooms/image-000.webp";
import dormitoryRoom from "../assets/vidhi-rooms/image-010.webp";
import "../css/style.css"
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import rom from "../assets/Home/room4/rom.JPG"
import { MetaTags } from 'react-meta-tags';
import bed from "../assets/double-bed.png"
import person from "../assets/couple-users-silhouette.png"
import wifi from "../assets/icon/wifi.png"
import roomListBanner from "../assets/vidhi/image-025.jpg";
const Rooms = ({banner}) => {
  return (
    <div>
        <Hadder></Hadder>
        <MetaTags>
        <title>Water Park with Rooms in Murbad | Vidhi Resort </title>
        <meta title=" Water Park with Rooms in Murbad | Vidhi Resort " />
        <meta
          name="description"
          content="Dive into a perfect blend of fun and relaxation at the Water Park with Rooms in Murbad. Enjoy thrilling slides and unwind in comfortable accommodations."
        />
        <meta
          name="keywords"
          content="Best Resort in Murbad, Resort in Murbad with Water Park,Best Resort in Murbad for Family,Water Park with Rooms in Murbad,Best Resort in Murbad with AC Rooms,Resort with Dormitory Rooms in Murbad,Best Water Park in Murbad,Top Resort near Murbad"
        />
        <link
          rel="canonical"
          href="https://vidhiresort.in/water-park-with-rooms-in-murbad"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content=" Water Park with Rooms in Murbad | Vidhi Resort "
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vidhiresort.in/water-park-with-rooms-in-murbad" />
        <meta
          property="og:description"
          content="Dive into a perfect blend of fun and relaxation at the Water Park with Rooms in Murbad. Enjoy thrilling slides and unwind in comfortable accommodations."
        />
        <meta
          property="og:image"
          content="https://vidhiresort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>
        <section className="page-header bg--cover second-banner" style={{backgroundImage:`url(${ banner})`}}>
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{color:"white"}}>Water Park with Rooms in Murbad</h1>
          </div>
        </div>
      </section>
      <section className="room padding-top padding-bottom">
  <div className="container">
  <div
            className="section-header aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="section-header__content">
              <h2>Vidhi Resort Best AC &amp; Dormitory Rooms.</h2>
             
            </div>
            
          </div>
          <div className="section-paragraph mb-5">
            <p className="text-align-center">
            Welcome to our water park with rooms in Murbad, where we invite you to discover a harmonious blend of luxury and affordability. Our water park with rooms in Murbad offers a range of accommodations to suit diverse preferences, ensuring a memorable stay for every guest.
        Indulge in the ultimate comfort of our AC rooms, where modern amenities and stylish decor converge to create an oasis of relaxation. Each room is meticulously designed to provide a haven of tranquility, offering a respite from the outside world. Whether you're traveling for business or leisure, our AC rooms provide the perfect retreat, ensuring a rejuvenating experience.
        
              </p>
              <p className="d-none">
              For those seeking a communal and cost-effective option, our dormitory rooms are the ideal choice. Immerse yourself in the joy of shared living without compromising on comfort.In our water park with rooms in Murbad, Our dormitories are spacious, well-appointed, and equipped with all the essentials for a comfortable stay. It's a perfect option for group travelers, backpackers, or those looking for a social and vibrant atmosphere.
        At our water park with rooms in Murbad, we prioritize your comfort and satisfaction. Both our AC rooms and dormitory rooms reflect our commitment to providing quality accommodations for a diverse range of guests. No matter your preference, each room is a testament to our dedication to creating a welcoming and enjoyable environment for your stay.
        Choose our water park with rooms in Murbad for a versatile and enriching experience, where the comfort of our AC rooms and the camaraderie of our dormitory rooms cater to the unique needs of every guest. Your journey to a delightful stay begins here, with accommodations designed to exceed your expectations.
              </p>
            </div>
    <div className="room__wrapper">
      <div className="row g-5">
      <Fade left>
        <div className="col-12">
          <div className="room__item room__item--style4">
            <div className="room__item-inner">
              <div className="room__item-thumb">
                <img src={acRoom} alt="New Dulex Room Kinara Resort-Couple" />
              </div>
              <div className="room__item-content">
                <div className="room__item-header">
                  <div className="room__item-name">
                   
                    <h3>
                      <a style={{textAlign:"left"}}>AC Rooms</a>
                    </h3>
                  
                  </div>
                  <div className="room__item-price">
                  </div>
                </div>
                <p>
                <div className="row">
                  <div className="col-md-4">
                  <div style={{textAlign:"left"}}>
                     
                      <li>Air Conditioning</li>
                    
                      <li>Private Entrance</li>
                      <li>Pool</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                <div style={{textAlign:"left"}}>
                      <li>Free Wi-Fi</li>
                      <li>Morning Nasta</li>
                      <li>Evening Tea</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{textAlign:"left"}}>
                  <li>Two Time Nasta</li>
                  <li>Veg , non-veg Food</li>
                  <li>Dinner</li>
                  </div>
                  </div>
                  </div>
                  </p>
                <div className="room__item-body">
                  
                  <ul className="room__feature room_icon">
                    <li className="room__feature-item">
                      <div className="room__feature-icon">
                        <img
                          src={person}
                          alt="4 Person"
                          style={{width:"30px"}}
                        />
                      </div>
                      <div className="room__feature-text">
                        <p>4 Person</p>
                      </div>
                    </li>
                    <li className="room__feature-item">
                      <div className="room__feature-icon">
                        <img
                          src={bed}
                          alt="1 Double bed"
                          style={{width:"30px"}}
                        />
                      </div>
                      <div className="room__feature-text">
                        <p>1 Double bed</p>
                      </div>
                    </li>
                    <li className="room__feature-item">
                      
                    </li>
                    
                  </ul>
                  <a className="custom-btn">
                    <span><Link to="/best-resort-in-murbad-with-ac-rooms">More Details</Link></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Fade>
        <Fade right>
        <div className="col-12">
          <div className="room__item room__item--style4 room__item--reverse">
            <div className="room__item-inner">
              <div className="room__item-thumb">
                <img src={dormitoryRoom} alt="Superior Family Room Wooden Cottage Kinara Resort" />
              </div>
              <div className="room__item-content">
                <div className="room__item-header">
                  <div className="room__item-name">
                   
                    <h3>
                      <a style={{textAlign:"left"}}>Dormitory Room</a>
                    </h3>
                    
                  </div>
                  <div className="room__item-price">
                 
                    
                  </div>
                </div>
                <p>
                <div className="row">
                  <div className="col-md-4">
                  <div style={{textAlign:"left"}}>
                     
                      <li>Air Conditioning</li>
                    
                      <li>Private Entrance</li>
                      <li>Pool</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                <div style={{textAlign:"left"}}>
                      <li>Free Wi-Fi</li>
                      <li>Morning Nasta</li>
                      <li>Evening Tea</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{textAlign:"left"}}>
                    {/* <li></li> */}
                  {/* <li>Beach View</li> */}
                  <li>Two Time Nasta</li>
                  <li>Veg , non-veg Food</li>
                  <li>Dinner</li>
                  </div>
                  </div>
                  </div>
                
                  </p>
                <div className="room__item-body">
                 
                  <ul className="room__feature room_icon">
                    <li className="room__feature-item">
                      <div className="room__feature-icon">
                        <img
                          src={person}
                          alt="4 Person"
                          style={{width:"30px"}}
                        />
                      </div>
                      <div className="room__feature-text">
                        <p>4 Person</p>
                      </div>
                    </li>
                    <li className="room__feature-item">
                      
                    </li>
                    <li className="room__feature-item">
                    <div className="room__feature-icon">
                        <img
                          src={bed}
                          alt="1 Double bed
                          & 1 Bunk bed"
                          style={{width:"30px"}}
                        />

                      </div>
                      <div className="room__feature-text">
                        <p>1 Double bed
                          & 1 Bunk bed
                        </p>
                      </div>
                    </li>
                   
                  </ul>
                  
                  <a className="custom-btn">
                    <span><Link to="/resort-with-dormitory-rooms-in-murbad">More Details</Link></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Fade>
        
      </div>
     
    </div>
  </div>
</section>


    </div>
  )
}

export default Rooms
