import s1 from "../assets/s1.jpg"
import s2 from "../assets/s2.jpg"
import s3 from "../assets/s3.jpg"
import s4 from "../assets/s4.jpg"
import s5 from "../assets/s5.jpg"
import s6 from "../assets/s6.webp"
import s7 from "../assets/s7.jpg"
import s8 from "../assets/s8.jpg"
import s9 from "../assets/s9.webp"
import s10 from "../assets/s10.jpg"
import ban1 from "../assets/Banner/ban1.jpeg"
import ban2 from "../assets/Banner/ban2.jpeg"
import ban3 from "../assets/Banner/ban3.JPG"
import ban4 from "../assets/Banner/ban4.JPG"
import ban5 from "../assets/Banner/ban5.JPG"
import ban6 from "../assets/Banner/ban6.JPG"
import ban7 from "../assets/Banner/ban7.JPG"
import ban8 from "../assets/Banner/ban8.JPG"
import ban9 from "../assets/Banner/ban9.JPG"
import g1 from "../assets/Home/Room1/G1.JPG"
import g2 from "../assets/Home/Room1/G2.JPG"
import g3 from "../assets/Home/Room1/G3.JPG"
import g4 from "../assets/Home/Room1/G4.JPG"
import g5 from "../assets/Home/Room1/G5.JPG"
import g6 from "../assets/Home/Room1/G6.JPG"
import g7 from "../assets/Home/Room1/G7.JPG"
import image000 from "../assets/gallery/image-000.webp";
import image001 from "../assets/gallery/image-001.webp";
import image002 from "../assets/gallery/image-002.webp";
import image003 from "../assets/gallery/image-003.webp";
import image004 from "../assets/gallery/image-004.webp";
import image005 from "../assets/gallery/image-005.webp";
import image006 from "../assets/gallery/image-006.webp";
import image007 from "../assets/gallery/image-007.webp";
import image008 from "../assets/gallery/image-008.webp";
import image009 from "../assets/gallery/image-009.webp";
import image010 from "../assets/gallery/image-010.webp";
import image011 from "../assets/gallery/image-011.webp";
import image012 from "../assets/gallery/image-012.webp";
import image013 from "../assets/gallery/image-013.webp";
import image014 from "../assets/gallery/image-014.webp";
import image015 from "../assets/gallery/image-015.webp";
import image016 from "../assets/gallery/image-016.webp";
import image017 from "../assets/gallery/image-017.webp";
import image018 from "../assets/gallery/image-018.webp";
import image019 from "../assets/gallery/image-019.webp";
import image020 from "../assets/gallery/image-020.webp";
import image021 from "../assets/gallery/image-021.webp";
import image022 from "../assets/gallery/image-021.webp";
import image023 from "../assets/gallery/image-023.webp";
import image024 from "../assets/gallery/image-024.webp";
import image025 from "../assets/gallery/image-025.webp";
import image026 from "../assets/gallery/image-026.webp";
import image027 from "../assets/gallery/image-027.webp";
export const photos = [
    {
      src:image000,
      width: 4,
      height: 3,
      alt: "Best Water Park ",
      loading:"lazy",
    },
    {
      src: image001,
      width: 1,
      height: 1,
      alt: " Water Park in Murbad",
      loading:"lazy",
    },
    {
      src:image002,
      width: 3,
      height: 4,
      alt: "Best Water Park ",
      loading:"lazy",
    },
    {
      src: image003,
      width: 3,
      height: 4,
      alt: " Water Park ",
      loading:"lazy",
    },
    {
      src: image004,
      width: 3,
      height: 4,
      alt: " Water Park in Murbad",
      loading:"lazy",
    },
    {
      src:image005,
      width: 4,
      height: 3,
      alt: "Best Water Park ",
      loading:"lazy",
    },
    {
      src:image006,
      width: 3,
      height: 4,
      alt: " Water Park in Murbad",
      loading:"lazy",
    },
    {
      src:image007,
      width: 4,
      height: 3,
      alt: " Water Park in Murbad",
      loading:"lazy",
    },
    {
      src: image008,
      width: 4,
      height: 3,
      alt: " Water Park in Murbad",
      loading:"lazy",
    },
    {
      src: image009,
      width: 4,
      height: 3,
      alt: "Best Water Park ",
      loading:"lazy",
    },
    {
      src: image010,
      width: 4,
      height: 3,
      alt: "Best Water Park ",
      loading:"lazy",
    },
    {
      src: image011,
      width: 4,
      height: 3,
      alt: " Water Park in Murbad",
      loading:"lazy",
    },
    {
      src: image012,
      width: 4,
      height: 3,
      alt: "Best Water Park ",
      loading:"lazy",
    },
    {
      src: image013,
      width: 4,
      height: 3,
      alt: " Park in Murbad",
      loading:"lazy",
    },
    {
      src: image014,
      width: 4,
      height: 3,
      alt: "Best Water Park ",
      loading:"lazy",
    },
    {
      src: image015,
      width: 4,
      height: 3,
      alt: " Park in Murbad",
      loading:"lazy",
    },
    {
      src: image016,
      width: 4,
      height: 3,
      alt: " Park in Murbad",
      loading:"lazy",
    },
    {
      src: image017,
      width: 4,
      height: 3,
      alt: "Best Water Park ",
      loading:"lazy",
    },
    {
      src: image018,
      width: 4,
      height: 3,
      alt: " Park in Murbad",
      loading:"lazy",
    },
    {
      src: image019,
      width: 4,
      height: 3,
      alt: " Park in Murbad",
      loading:"lazy",
    },
    {
      src: image020,
      width: 4,
      height: 3,
      alt: "Best Water Park ",
      loading:"lazy",
    },
    {
      src: image021,
      width: 4,
      height: 3,
      alt: "Best Water Park ",
      loading:"lazy",
    },
    {
      src: image022,
      width: 4,
      height: 3,
      alt: "Best Water  in Murbad",
      loading:"lazy",
    },
    {
      src: image023,
      width: 4,
      height: 3,
      alt: " Water Park in Murbad",
      loading:"lazy",
    },
    {
      src: image024,
      width: 4,
      height: 3,
      alt: " Park in Murbad",
      loading:"lazy",
    },
    {
      src: image025,
      width: 4,
      height: 3,
      alt: " Park in Murbad",
      loading:"lazy",
    },
    {
      src: image026,
      width: 4,
      height: 3,
      alt: " Water Park in Murbad",
      loading:"lazy",
    },
    {
      src: image027,
      width: 4,
      height: 3,
      alt: "Best Water Park ",
      loading:"lazy",
    },
  ];
  