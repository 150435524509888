import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Flip from "react-reveal/Flip";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ban3 from "../assets/Banner/ban3.JPG";
import "../css/style.css";
import "../css/Home.css";
import ban5 from "../assets/Banner/ban5.JPG";
import ban2 from "../assets/Banner/ban2.jpeg"
import bann1 from "../assets//Home/bann1.jpeg";
import bann2 from "../assets/Home/bann2.jpeg";
import banner1 from "../assets/vidhi/image-016.jpg";
// import required modules
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
const Slider = ({banner}) => {
  return (
    <div>
      <>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
         
          modules={[Autoplay]}
          className="homebanner"
        >
          <SwiperSlide>
            {" "}
            <section className="hero">
              <div className="hero__slider swiper swiper-creative swiper-3d swiper-initialized swiper-horizontal swiper-watch-progress">
                <div
                  className="swiper-wrapper"
                  id="swiper-wrapper-4e96858327ae7d8b"
                  aria-live="polite"
                  style={{ cursor: "grab" }}
                >
                  <div
                    className="swiper-slide swiper-slide-visible swiper-slide-active"
                    role="group"
                    aria-label="1 / 3"
                    data-swiper-slide-index={0}
                    style={{
                      width: "100%",
                      zIndex: 3,
                      transform:
                        "translate3d(calc(0px), calc(0px), calc(0px)) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale(1)",
                      opacity: 1,
                      marginRight: 20,
                    }}
                  >
                    <div
                      className="hero__wrapper"
                      style={{ backgroundImage: `url(${banner})` }}
                    >
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-8">
                            <Flip right>
                              <div className="hero__content hero__content--center padding10">
                                <h1>
                                Best Resort in Murbad
                                </h1>
                                <p className="text-lg">
                                Book your stay 
                                </p>
                                <a href="#" className="custom-btn">
                                  <span>
                                    <Link to="/best-water-park-in-murbad">
                                      Explore Room
                                    </Link>
                                  </span>
                                </a>
                              </div>
                            </Flip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  );
};

export default Slider;
