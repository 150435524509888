import Hadder from "../components/Hadder";
import Footer from "../components/Footer";
import ban5 from "../assets/vidhi/image-025.jpg";
import { MetaTags } from "react-meta-tags";
import { useLocation } from "react-router-dom";
import $ from "jquery";

import React, { useRef, useLayoutEffect, useState } from "react";
const Contact = ({ banner }) => {
  // email
  const formRef = useRef(null);
  const [custName, setcustName] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [formError, setFormError] = useState(false);

  const ContactEnquiry = (e) => {
    e.preventDefault();

    // Validate form fields
    if (
      !custName ||
      !custContact ||
      !custMessage ||
      !custEmail ||
      !custSubject
    ) {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    // Your form submission logic goes here
    var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Vidhi Resort Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Vidhi Resort Hall</p></div></body></html>`;

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "vidhiresort47@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "vidhiresort",
        accountLeadSource: "https://skdm.in",
        accountLeadName: custName,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
      }
    );

    // Reset the form
    if (formRef.current) {
      formRef.current.reset();
    }

    alert("Your Form has Submitted. Our team will contact you soon.");
  };

  const search = useLocation();
  const Path = search.pathname;

  // const [edata, setEdata] = useState(Servicedata)
  // // const [Fdata,setFdata] = useState([])

  // const EventFdata = edata.find(e => e.slug == Path)
  // // setFdata(EventFdata);

  // console.log(EventFdata)
  return (
    <div>
      <Hadder></Hadder>
      <MetaTags>
        <title>Top Resort near Murbad | Vidhi Resort </title>
        <meta title=" Top Resort near Murbad | Vidhi Resort   " />
        <meta
          name="description"
          content="Discover unparalleled luxury and serenity at the Top Resort near Murbad. Escape the ordinary and indulge in extraordinary experiences."
        />
        <meta
          name="keywords"
          content="Best Resort in Murbad, Resort in Murbad with Water Park,Best Resort in Murbad for Family,Water Park with Rooms in Murbad,Best Resort in Murbad with AC Rooms,Resort with Dormitory Rooms in Murbad,Best Water Park in Murbad,Top Resort near Murbad"
        />
        <link
          rel="canonical"
          href="https:/vidhiresort.in/top-resort-near-murbad"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content=" Top Resort near Murbad | Vidhi Resort   "
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https:/vidhiresort.in/top-resort-near-murbad"
        />
        <meta
          property="og:description"
          content="Discover unparalleled luxury and serenity at the Top Resort near Murbad. Escape the ordinary and indulge in extraordinary experiences."
        />
        <meta
          property="og:image"
          content="https:/vidhiresort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>
      <section
        className="page-header bg--cover second-banner"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{ color: "white" }}>Top Resort near Murbad</h1>
          </div>
        </div>
      </section>
      <section className="contact padding-top padding-bottom">
        <div className="container">
          <div className="contact__wrapper">
            <div className="row g-5">
              <div className="col-lg-6">
                <div className="contact__info">
                  <div className="common-header">
                    <p className="subtitle textleft">vidhi Resort</p>
                    <h2 className="textleft">
                      Know more about <br /> our Resort
                    </h2>
                  </div>
                  <p className="textleft">
                    Feel free to reach out through the provided contact
                    information, including phone numbers and email addresses. We
                    are committed to offering personalized assistance, ensuring
                    that your interactions with us are not only efficient but
                    also tailored to your specific needs.
                  </p>
                  <p className="textleft d-none">
                    Welcome to our Contact Us page, the gateway to connecting
                    with our top resort near Murbad Your inquiries, feedback,
                    and requests are important to us, and we're here to ensure
                    your experience is seamless from the very beginning. Whether
                    you have questions about room availability, event planning,
                    or general inquiries, in our top resort near Murbad, our
                    dedicated team is ready to assist you. We value open
                    communication and strive to provide timely and helpful
                    responses to all your queries. Feel free to reach out
                    through the provided contact information, including phone
                    numbers and email addresses. We are committed to offering
                    personalized assistance, ensuring that your interactions
                    with us are not only efficient but also tailored to your
                    specific needs. Additionally, you can use the contact form
                    on this page to send us a message directly. Please provide
                    details about your inquiry, and our team will get back to
                    you promptly. Your satisfaction is our priority, and we look
                    forward to hearing from you. Thank you for considering the
                    top resort near Murbad, and we anticipate the opportunity to
                    assist you in creating a memorable experience at our resort.
                  </p>
                  <ul className="contact__info-list">
                    <li className="contact__info-item">
                      <div className="contact__info-icon">
                        <svg
                          className="svg-inline--fa fa-location-dot"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="location-dot"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                          />
                        </svg>
                      </div>
                      <div className="contact__info-content">
                        <h6 className="textleft">Office Address</h6>
                        <p className="textleft">
                          Vidhi Resort & Water Park near New Kachkoli, Mhasa,
                          <br></br> Kanhol, Maharashtra 421401
                        </p>
                      </div>
                    </li>
                    <li className="contact__info-item">
                      <div className="contact__info-icon">
                        <svg
                          className="svg-inline--fa fa-phone"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="phone"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                          />
                        </svg>
                      </div>
                      <div className="contact__info-content">
                        <h6 className="textleft">Phone Number</h6>
                        <p className="textleft">
                          <a
                            href="tel:+918097091999"
                            className="text-muted d-block "
                          >
                            +91 8097091999
                          </a>
                          {/* <br/> */}
                          <a
                            href="tel:+919167721999"
                            className="text-muted d-block "
                          >
                            +91 9167721999
                          </a>
                        </p>
                      </div>
                    </li>
                    <li className="contact__info-item">
                      <div className="contact__info-icon">
                        <svg
                          className="svg-inline--fa fa-envelope"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="envelope"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
                          />
                        </svg>
                      </div>
                      <div className="contact__info-content">
                        <h6 className="textleft">Office Address</h6>
                        <p className="textleft text-muted">
                          <a
                            className="text-muted"
                            href="vidhiresort47@gmail.com"
                          >
                            vidhiresort47@gmail.com
                          </a>{" "}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact__form">
                  <h4>Send us a message</h4>
                  <form method="post" ref={formRef} action="" id="contact-form">
                    <div className="row g-4">
                      <div className="col-md-6">
                        <div className="booking__form-inputgroup">
                          <div className="booking__form-input">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Your Name"
                              onChange={(e) => setcustName(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="booking__form-inputgroup">
                          <div className="booking__form-input">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Email Address"
                              onChange={(e) => setCustEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="booking__form-inputgroup">
                          <div className="booking__form-input">
                            <input
                              type="number"
                              name="contact"
                              placeholder="Your Contact Number"
                              className="form-control"
                              onChange={(e) => setCustContact(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="booking__form-inputgroup">
                          <div className="booking__form-input">
                            <input
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              onChange={(e) => setCustSubject(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" col-12">
                        <div className="booking__form-inputgroup">
                          <div className="booking__form-input">
                            <textarea
                              name="message"
                              cols={30}
                              rows={5}
                              className="form-control"
                              placeholder="Write Your Message"
                              onChange={(e) => setCustMessage(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {formError && (
                      <p style={{ color: "red" }}>
                        Please fill in all required fields
                      </p>
                    )}
                    <button
                      type="submit"
                      className="custom-btn mt-40"
                      onClick={ContactEnquiry}
                    >
                      Send Message <i className="far fa-paper-plane" />
                    </button>
                    {/* <button className="custom-btn mt-40">
                <span>Submit Now</span>
              </button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="map paddding-top padding-bottom">
        <div className="container">
          <div className="map__wrapper map-pad">
            <iframe
              style={{ borderRadius: "25px" }}
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3768.6394185833083!2d73.4325311!3d19.1672548!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7f5788b605951%3A0x5eeab3ed7cbfeaf9!2sVidhi%20Resort%20%26%20Water%20Park!5e0!3m2!1sen!2sin!4v1706007678347!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
