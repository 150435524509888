import React, { useRef, useState } from 'react';
// Import Swiper React components
import $ from "jquery";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Hadder from '../components/Hadder'
import Footer from '../components/Footer'
import Accordion from 'react-bootstrap/Accordion';
import room2 from "../assets/Home/room2.jpeg"
import r1 from "../assets/Home/Room2/r1.jpeg"
import r2 from "../assets/Home/Room2/r2.jpeg"
import r3 from "../assets/Home/Room2/r3.jpeg"
import ban5 from "../assets/Banner/ban5.JPG"
import { MetaTags } from 'react-meta-tags';
import bed from "../assets/double-bed.png"
import person from "../assets/couple-users-silhouette.png"
import wifi from "../assets/wifi1.png"
import sunn1 from "../assets/sunny/sunn1.jpeg"
import sunn2 from "../assets/sunny/sunn2.jpeg"
import sunn3 from "../assets/sunny/sunn3.jpeg"
import sunn4 from "../assets/sunny/sunn4.jpeg"
import sunn5 from "../assets/sunny/sunn5.jpeg"
import sunn6 from "../assets/sunny/sunn6.jpeg"
import dormitoryRoom1 from "../assets/Rooms/image-008.jpg";
import dormitoryRoom2 from "../assets/vidhi-rooms/image-009.webp";
import dormitoryRoom3 from "../assets/vidhi-rooms/image-010.webp";
import dormitoryRoomBanner from "../assets/vidhi/image-025.jpg";
const Roomdetail = ({banner}) => {
  // email
  const formRef = useRef(null);
  const [custName, setcustName] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [formError, setFormError] = useState(false);

  const ContactEnquiry = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!custName || !custContact || !custMessage || !custEmail || !custSubject) {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    // Your form submission logic goes here
    var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Vidhi Resort Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Vidhi Resort</p></div></body></html>`;

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "vidhiresort47@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "vidhiresort",
        accountLeadSource: "https://skdm.in",
        accountLeadName: custName,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
      }
    );

    // Reset the form
    if (formRef.current) {
      formRef.current.reset();
    }

    alert("Your Form has Submitted. Our team will contact you soon.");

  };
  return (
    <div>
        <Hadder></Hadder>
        <MetaTags>
        <title>Resort with Dormitory Rooms in Murbad | Vidhi Resort  </title>
        <meta title=" Resort with Dormitory Rooms in Murbad | Vidhi Resort  " />
        <meta
          name="description"
          content="Discover the perfect blend of comfort and camaraderie at our Resort with Dormitory Rooms in Murbad. Ideal for group getaways, enjoy a cozy stay ."
        />
        <meta
          name="keywords"
          content="Best Resort in Murbad, Resort in Murbad with Water Park,Best Resort in Murbad for Family,Water Park with Rooms in Murbad,Best Resort in Murbad with AC Rooms,Resort with Dormitory Rooms in Murbad,Best Water Park in Murbad,Top Resort near Murbad"
        />
        <link
          rel="canonical"
          href="https://https://vidhiresort.in/resort-with-dormitory-rooms-in-murbad"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content=" Resort with Dormitory Rooms in Murbad | Vidhi Resort  "
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://https://vidhiresort.in/resort-with-dormitory-rooms-in-murbad" />
        <meta
          property="og:description"
          content="Discover the perfect blend of comfort and camaraderie at our Resort with Dormitory Rooms in Murbad. Ideal for group getaways, enjoy a cozy stay ."
        />
        <meta
          property="og:image"
          content="https://https://vidhiresort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>
        <section className="page-header bg--cover second-banner" style={{backgroundImage:`url(${ banner})`}}>
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{color:"white"}}>Resort with Dormitory Rooms in Murbad </h1>
           
             
          
          </div>
        </div>
      </section>
     <section className="room padding-top padding-bottom">
  <div className="container">
    <div className="room__wrapper">
      <div className="row g-5">
        <div className="col-lg-8">
          <div className="room__details">
          <h3 className='pad10'>Dormitory Room</h3>
            <div className="room__details-image">
            <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules= {[ Pagination, Navigation]}
        className="mySwiper"
      >
      <SwiperSlide>
        <div className='insideslider'><img src={dormitoryRoom3} loading='lazy' alt=' Dormitory Rooms in Murbad' /></div>  
     </SwiperSlide>
    <SwiperSlide>
      <div className='insideslider'><img src={dormitoryRoom2} loading='lazy' alt='Resort with Dormitory ' /></div>
      </SwiperSlide>
   
       
      </Swiper>
            </div>
            <div className="room__details-content">
             
             
              
              <div className="room__details-text">
                <h4>Description</h4>
                <p style={{textAlign:"justify"}}>
                Discover the charm of our resort with dormitory rooms in Murbad, our thoughtfully designed dormitory rooms, blending affordability with comfort. From the moment you step through your private entrance, a world of relaxation unfolds. Our air-conditioned resort with dormitory rooms in Murbad provides a cozy retreat, ensuring a restful stay during your escapades.
                Dive into refreshing moments at our inviting pool, where camaraderie and relaxation seamlessly coexist. Whether you're traveling solo or with friends, our resort with dormitory rooms in Murbad offers a communal yet comfortable atmosphere, fostering connections with fellow travelers. <br/>
                Stay connected with our complimentary Wi-Fi, allowing you to share your experiences online or plan your next adventure. Kickstart your day with a delightful morning nasta, a perfect prelude to the exciting exploration that awaits you.<br/>
                </p>
                <p className='d-none'>
                As the sun sets, unwind by the poolside with evening tea, creating memories against the backdrop of our scenic surroundings.In resort with dormitory rooms in Murbad Our commitment to your well-being extends to your taste buds, with two-time nasta offerings that cater to varied preferences. Whether you're a vegetarian enthusiast or a fan of non-vegetarian delights, our diverse menu ensures a satisfying culinary experience.<br/>
                Cap off your day with a delectable dinner that transcends the ordinary. Our resort with dormitory rooms in Murbad takes pride in offering a culinary journey that elevates your dining experience. Indulge in a selection of veg and non-veg delicacies, expertly crafted to tantalize your palate and leave a lasting impression.
                In our resort with dormitory rooms in Murbad, we redefine affordability without compromising on quality. Embrace the spirit of community, enjoy the comfort of air-conditioning, and relish the array of amenities that make your stay at our resort memorable. Your journey is not just a stay; it's an immersive experience in a welcoming environment designed for travelers seeking both adventure and camaraderie.<br/>

                </p>
                <ul className="room__feature">
                <li className="room__feature-item padding-text">
                  <div className="room__feature-icon">
                    <img
                       src={person}
                      alt="4 Person"
                      style={{width:"30px"}}
                    />
                  </div>
                  <div className="room__feature-text">
                    <p className='font-size'>10-20 Person</p>
                  </div>
                </li>
                <li className="room__feature-item padding-text">
                  <div className="room__feature-icon">
                    <img
                       src={bed}
                      alt="1 Double bed & 1 Bunk bed"
                      style={{width:"30px"}}
                    />
                  </div>
                  <div className="room__feature-text">
                    <p  className='font-size'> Multiple bed 
                    </p>
                  </div>
                </li>
                <li className="room__feature-item padding-text">
                  <div className="room__feature-icon">
                    <img
                      src={wifi}
                      alt="free wifi"
                      style={{width:"30px"}}
                    />
                  </div>
                  <div className="room__feature-text">
                    <p className='font-size'>free wifi</p>
                  </div>
                </li>
             
               
              </ul>
              </div>
             
            </div>
          </div>
      
    
        </div>
        <div className="col-lg-4 col-md-8">
          <aside>
          <div className="contact__form">
            <h4>Send us a message</h4>
            <form method="post"
            ref={formRef}
            action=""
            id="contact-form">
              <div className="row g-4">
                <div className="col-md-12">
                  <div className="booking__form-inputgroup">
                    <div className="booking__form-input">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        onChange={(e) => setcustName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="booking__form-inputgroup">
                    <div className="booking__form-input">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email "
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="booking__form-inputgroup">
                    <div className="booking__form-input">
                      <input
                        type="number"
                        name="contact"
                        placeholder="Contact Number"
                        className="form-control"
                        onChange={(e) => setCustContact(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="booking__form-inputgroup">
                    <div className="booking__form-input">
                      <input
                        className="form-control"
                        name="subject"
                        placeholder="Subject"
                        onChange={(e) => setCustSubject(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className=" col-md-12">
                  <div className="booking__form-inputgroup">
                    <div className="booking__form-input">
                    
                      <textarea
                         name="message"
                        cols={30}
                        rows={5}
                        className="form-control"
                        placeholder="Write Your Message"
                        onChange={(e) => setCustMessage(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {formError && (
          <p style={{ color: "red" }}>Please fill in all required fields</p>
        )}
        <button type="submit" className="custom-btn mt-40" onClick={ContactEnquiry}>
          Send Message <i className="far fa-paper-plane" />
        </button>
              {/* <button className="custom-btn mt-40">
                <span>Submit Now</span>
              </button> */}
            </form>
          </div>
          </aside>
        </div>
      </div>
    </div>
  </div>
</section>
{/* <Footer></Footer> */}
    </div>
  )
}

export default Roomdetail
