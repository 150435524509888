import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import $ from "jquery";
import Hadder from '../components/Hadder'
import Footer from '../components/Footer'
import Accordion from 'react-bootstrap/Accordion';
import room1 from "../assets/Home/room1.JPG"
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import R1 from "../assets/Home/Room1/R1.jpeg"
import R2 from "../assets/Home/Room1/R2.jpeg"
import R3 from "../assets/Home/Room1/R3.jpeg"
import R4 from "../assets/Home/Room1/R4.jpeg"
import R5 from "../assets/Home/Room1/R5.jpeg"
import R6 from "../assets/Home/Room1/R6.jpeg"
import ban5 from "../assets/Banner/ban5.JPG"
import { MetaTags } from 'react-meta-tags';
import bed from "../assets/double-bed.png"
import person from "../assets/couple-users-silhouette.png"
import wifi from "../assets/wifi1.png"
import spr1 from "../assets/spring/spr1.jpeg"
import spr2 from "../assets/spring/spr2.jpeg"
import spr3 from "../assets/spring/spr3.jpeg"
import spr4 from "../assets/spring/spr4.jpeg"
import spr5 from "../assets/spring/spr5.jpeg"
import spr6 from "../assets/spring/spr6.jpeg"
import spr7 from "../assets/spring/spr7.jpeg"
import spr8 from "../assets/spring/spr8.jpeg"
import spr9 from "../assets/spring/spr9.jpeg"
import acRoom1 from "../assets/vidhi-rooms/image-000.webp";
import acRoom2 from "../assets/vidhi-rooms/image-001.webp";
import acRoom3 from "../assets/vidhi-rooms/image-002.webp";
import acRoom4 from "../assets/vidhi-rooms/image-003.webp";
import acRoom5 from "../assets/vidhi-rooms/image-005.webp";
import acRoom6 from "../assets/Rooms/image-006.jpg";
import acRoom7 from "../assets/vidhi-rooms/image-007.webp";
import acRoom8 from "../assets/vidhi-rooms/image-011.webp";
import acRoomBanner from "../assets/vidhi/image-023.jpg";
const Roomdetail = ({banner}) => {
  // email
  const formRef = useRef(null);
  const [custName, setcustName] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [formError, setFormError] = useState(false);

  const ContactEnquiry = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!custName || !custContact || !custMessage || !custEmail || !custSubject) {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    // Your form submission logic goes here
    var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Vidhi Resort Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Classic Villa Party Hall</p></div></body></html>`;

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "aslampathan7758@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "vidhiresort",
        accountLeadSource: "https://skdm.in",
        accountLeadName: custName,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
      }
    );

    // Reset the form
    if (formRef.current) {
      formRef.current.reset();
    }

    alert("Your Form has Submitted. Our team will contact you soon.");

  };
  return (
    <div>
        <Hadder></Hadder>
        <MetaTags>
        <title>Best Resort in Murbad with AC Rooms | Vidhi Resort </title>
        <meta title=" Best Resort in Murbad with AC Rooms | Vidhi Resort " />
        <meta
          name="description"
          content="Experience unparalleled comfort in every season at the Best Resort in Murbad with AC Rooms. Indulge in cool luxury, ensuring a refreshing & relaxing escape."
        />
        <meta
          name="keywords"
          content="Best Resort in Murbad, Resort in Murbad with Water Park,Best Resort in Murbad for Family,Water Park with Rooms in Murbad,Best Resort in Murbad with AC Rooms,Resort with Dormitory Rooms in Murbad,Best Water Park in Murbad,Top Resort near Murbad"
        />
        <link
          rel="canonical"
          href="https://vidhiresort.in/best-resort-in-murbad-with-ac-rooms"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content=" Best Resort in Murbad with AC Rooms | Vidhi Resort "
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vidhiresort.in/best-resort-in-murbad-with-ac-rooms" />
        <meta
          property="og:description"
          content="Experience unparalleled comfort in every season at the Best Resort in Murbad with AC Rooms. Indulge in cool luxury, ensuring a refreshing & relaxing escape."
        />
        <meta
          property="og:image"
          content="https://vidhiresort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>
        <section className="page-header bg--cover second-banner" style={{backgroundImage:`url(${ banner})`}}>
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{color:"white"}}>Best Resort in Murbad with AC Rooms</h1>
          </div>
        </div>
      </section>
     
     <section className="room padding-top padding-bottom">
  <div className="container">
    <div className="room__wrapper">
      <div className="row g-5">
        <div className="col-lg-8">
          <div className="room__details">
          <h3 className='pad10'>Ac Rooms</h3>
            <div className="">
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide><img className='ac-rooms-img' style={{width:"90%" ,height:"567px"}} src={acRoom8} loading='lazy' alt='Best Resort in Murbad ' /></SwiperSlide>
        <SwiperSlide><img className='ac-rooms-img' style={{width:"90%" ,height:"567px"}} src={acRoom2} loading='lazy' alt='Best Resort in Murbad ' /></SwiperSlide>
        <SwiperSlide><img className='ac-rooms-img' style={{width:"90%" ,height:"567px"}} src={acRoom3} loading='lazy' alt='Best Resort in Murbad ' /></SwiperSlide>
        <SwiperSlide><img className='ac-rooms-img' style={{width:"90%" ,height:"567px"}} src={acRoom4} loading='lazy' alt='Best Resort in Murbad ' /></SwiperSlide>
        <SwiperSlide><img className='ac-rooms-img' style={{width:"90%" ,height:"567px"}} src={acRoom5} loading='lazy' alt='Best Resort in Murbad ' /></SwiperSlide>
        <SwiperSlide><img className='ac-rooms-img' style={{width:"90%" ,height:"567px"}} src={acRoom7} loading='lazy' alt='Best Resort in Murbad ' /></SwiperSlide>
        <SwiperSlide><img className='ac-rooms-img' style={{width:"90%" ,height:"567px"}} src={acRoom1} loading='lazy' alt='Best Resort in Murbad ' /></SwiperSlide>
      </Swiper>
            {/* <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[ Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img style={{width:"auto" ,height:"567px"}} src={acRoom8} loading='lazy' alt='Best Resort in Murbad ' />
          
     </SwiperSlide>
<SwiperSlide>
<div className='insideslider1'><img style={{width:"auto" ,height:"567px"}} src={acRoom2} loading='lazy' alt=' Murbad with AC Rooms' /></div>
    </SwiperSlide>
    <SwiperSlide>
    <div className='insideslider1'><img style={{width:"auto" ,height:"567px"}} src={acRoom3} loading='lazy' alt=' Murbad with AC Rooms' /></div>
    </SwiperSlide>
    <SwiperSlide>
    <div className='insideslider1'><img style={{width:"auto" ,height:"567px"}} src={acRoom4}  loading='lazy' alt='Best Resort in Murbad' /></div>
    </SwiperSlide>
    <SwiperSlide>
    <div className='insideslider1'><img style={{width:"auto" ,height:"567px"}} src={acRoom5} loading='lazy' alt=' in Murbad with AC Rooms' /></div>
    </SwiperSlide>
    <SwiperSlide>
    <div className='insideslider1'><img style={{width:"auto" ,height:"567px"}} src={acRoom7} loading='lazy' alt=' Murbad with AC Rooms' /></div>
    </SwiperSlide>
    <SwiperSlide>
    <div className='insideslider1'><img style={{width:"auto" ,height:"567px"}} src={acRoom1} loading='lazy' alt='  Murbad with AC Rooms' /></div>
    </SwiperSlide>
  

       
            </Swiper> */}
            </div>
            <div className="room__details-content">
              
              
              
              <div className="room__details-text">
                <h4>Description</h4>
                <p style={{textAlign:"justify"}}>
                Welcome to our best resort in Murbad with ac rooms, where comfort meets luxury in our air-conditioned rooms. Your journey begins with a private entrance, ensuring a seamless transition into your oasis of relaxation. Immerse yourself in the refreshing ambiance as our rooms are equipped with state-of-the-art air conditioning to provide you with the utmost comfort.<br/>
                Indulge in a rejuvenating experience by taking a dip in our inviting pool, offering a perfect escape from the hustle and bustle of daily life. Whether you prefer a leisurely swim or basking in the sun poolside, our  best resort in Murbad with ac room provides the ideal setting for your tranquil getaway.<br/>
                Stay connected with complimentary Wi-Fi, allowing you to share your memorable moments with friends and family instantly. Begin your day on a delightful note with our morning nasta, setting the tone for a day filled with exploration and relaxation. <br/>
                 
                </p>
            <p className='d-none'>
            As the sun sets, unwind with evening tea, savoring the picturesque surroundings. Delight your taste buds with our two-time nasta, ensuring you stay energized throughout your stay. Our culinary offerings extend beyond just snacks, with a diverse menu featuring both vegetarian and non-vegetarian options.<br/>
                Cap off your day with a delectable dinner experience. In the best resort in Murbad with ac room Our chefs craft a culinary journey, offering a selection of mouth-watering dishes to satisfy every palate. Whether you crave the richness of non-veg or the freshness of vegetarian delights, our dining experience promises to be a highlight of your stay. <br/>
                At our best resort in Murbad with ac room, we strive to create a haven where every moment is cherished, and every need is met. Your comfort is our priority, and we invite you to immerse yourself in the serenity and luxury that our air-conditioned rooms and exceptional amenities provide.

            </p>
                <ul className="room__feature">
                <li className="room__feature-item padding-text" >
                  <div className="room__feature-icon">
                    <img
                      src={person}
                      loading='lazy'
                      alt=" in Murbad with AC Rooms"
                      style={{width:"30px"}}
                    />
                  </div>
                  <div className="room__feature-text">
                    <p className='font-size'>2 Person</p>
                  </div>
                </li>
                <li className="room__feature-item padding-text">
                  <div className="room__feature-icon">
                    <img
                      src={bed}
                      loading='lazy'
                      alt="Best Resort in Murbad "
                      style={{width:"30px"}}
                    />
                  </div>
                  <div className="room__feature-text">
                    <p className='font-size'>4 Double bed</p>
                  </div>
                </li>
                <li className="room__feature-item padding-text  ">
                  <div className="room__feature-icon">
                    <img
                      src={wifi}
                      loading='lazy'
                      alt="Best Resort in Murbad"
                       style={{width:"30px"}}
                    />
                  </div>
                  <div className="room__feature-text">
                    <p className='font-size'>free wifi</p>
                  </div>
                </li>
             
               
              </ul>
              </div>
             
            </div>
          </div>
      
    
        </div>
        <div className="col-lg-4 col-md-12">
          <aside>
          <div className="contact__form">
            <h4>Send us a message</h4>
            <form method="post"
            ref={formRef}
            action=""
            id="contact-form">
              <div className="row g-4">
                <div className="col-md-12">
                  <div className="booking__form-inputgroup">
                    <div className="booking__form-input">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        onChange={(e) => setcustName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="booking__form-inputgroup">
                    <div className="booking__form-input">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email "
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="booking__form-inputgroup">
                    <div className="booking__form-input">
                      <input
                        type="number"
                        name="contact"
                        placeholder="Contact Number"
                        className="form-control"
                        onChange={(e) => setCustContact(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="booking__form-inputgroup">
                    <div className="booking__form-input">
                      <input
                        className="form-control"
                        name="subject"
                        placeholder="Subject"
                        onChange={(e) => setCustSubject(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className=" col-md-12">
                  <div className="booking__form-inputgroup">
                    <div className="booking__form-input">
                    
                      <textarea
                         name="message"
                        cols={30}
                        rows={5}
                        className="form-control"
                        placeholder="Write Your Message"
                        onChange={(e) => setCustMessage(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {formError && (
          <p style={{ color: "red" }}>Please fill in all required fields</p>
        )}
        <button type="submit" className="custom-btn mt-40" onClick={ContactEnquiry}>
          Send Message <i className="far fa-paper-plane" />
        </button>
              {/* <button className="custom-btn mt-40">
                <span>Submit Now</span>
              </button> */}
            </form>
          </div>
            {/* <div className='map-pad'>
            <div className='widget widget-booking '>
            <div className='booking__wrapper booking__wrapper--has-shadow bg-section-color '>
            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>General info</Accordion.Header>
        <Accordion.Body>
            <ul style={{textAlign:"left"}}>
                <li>✓ The room includes 1 Double Sized Bed </li>
               
            </ul>
        
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> In Your Private Bathroom</Accordion.Header>
        <Accordion.Body>
          <ul style={{textAlign:"left"}}>
            <li>✓ The bathroom has accessible to toiletries</li>
            <li>✓ The room has a balcony which has a beautiful beach view </li>
          
           
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>View</Accordion.Header>
        <Accordion.Body>
          <ul style={{textAlign:"left"}}>
            <li>✓ The room has a private entrance, and a beautiful view of the terrace </li>
           
            
          </ul>
        </Accordion.Body>
      </Accordion.Item>
     
      <Accordion.Item eventKey="3">
        <Accordion.Header>Included</Accordion.Header>
        <Accordion.Body>
          <ul style={{textAlign:"left"}}>
            <li>✓ Fan</li>
            <li>✓  Towels</li>
            <li>✓  Socket Near Bed</li>
            <li>✓  Tile/Marble Floor</li>
            <li>✓  Air Conditioning</li>
            <li>✓  Electric Kettle</li>
            <li>✓  Private entrance</li>
            <li>✓  Terrace</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Smoking</Accordion.Header>
        <Accordion.Body>
          <p style={{textAlign:"left"}}>
          ✓ No smoking designated area available in the property 
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Great Location</Accordion.Header>
        <Accordion.Body>
        
              <p style={{textAlign:"left",textDecoration:"underline"}}>  Attraction</p> 
              <p style={{textAlign:"left"}}>
              All distances are measured in straight lines actual travel distances may vary</p>

         
                <p style={{textAlign:"left",textDecoration:"underline"}}>Eat and Drink</p>
            <ul style={{textAlign:"left"}} >
               
                <li>
                Rasam Restaurant (9km)
                </li>
                <li>
                Bars and Breeze Cafe (7km)
                </li>
                <li>
                    Cafes bars breeze cafe : 7km
                </li>
                <li>
                reliance super marker : 9km
                </li>
            </ul>
        
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header> Accomodation policies</Accordion.Header>
        <Accordion.Body>
          <p style={{textAlign:"left"}}>
          Check In Time is 1:00 pm
          </p>
          <p style={{textAlign:"left"}}>
          Check Out Time is 11:00 am 
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>  No age Restriction</Accordion.Header>
        <Accordion.Body>
          <p style={{textAlign:"left"}}>
          There is no age restrictions in the premises 
          </p>
          
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header>  Pets</Accordion.Header>
        <Accordion.Body>
          <p style={{textAlign:"left"}}>
          Pets are allowed in the in the premises 
          </p>
          
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header>Accepted payment method </Accordion.Header>
        <Accordion.Body>
          <p style={{textAlign:"left"}}>
          We accept payment in Cash or UPI
          </p>
         
          
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </div>
            </div>
            </div> */}
          </aside>
        </div>
      </div>
    </div>
  </div>
</section>
{/* <Footer></Footer> */}
    </div>
  )
}

export default Roomdetail
